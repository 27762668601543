<template>
  <div id="scorecard-scorecard-content" v-if="loaded">
    <div style="margin-bottom: 16px">
      <div
        v-if="!deadlineExpired && !submitted"
        style="font-size: 18px; line-height: 24px"
      >
        {{ $t("SubmitDate") }} 03.{{ monthTwoFormat }}. 23:59
      </div>
      <div>
        {{ $t("Status") }}:
        <span
          v-if="userScorecards[year].months[month].submitted"
          style="color: #0aaf60"
        >
          {{ $t("Submitted") }}
        </span>
        <span v-else style="color: #ff1e24">
          {{ $t("NotSubmitted") }}
        </span>
      </div>
    </div>
    <div class="scorecard-form">
      <div class="scorecard-form-header content-small">
        <span style="width: 88px; margin-left: 108px">
          {{ $t("UnitPrice") }}
        </span>
        <span style="width: 408px">
          {{ $t("Activity") }}
        </span>
        <span>
          {{ $t("Quantity") }}
        </span>
      </div>
      <div v-if="template && template.topics" class="scorecard-form-body">
        <div v-for="topic in template.topics" :key="topic.id" class="topic-row">
          <div class="topic-title">
            {{ topic.title.et }}
          </div>
          <div v-for="field in topic.fields" :key="field.id">
            <div class="topic-field-row">
              <div class="topic-field-row-content">
                <span
                  style="
                    min-width: 40px;
                    height: 24px;
                    font-size: 18px;
                    line-height: 24px;
                    font-family: Inter, sans-serif;
                  "
                >
                  {{ field.unitPrice }}
                </span>
                <div class="topic-field-row-content-info">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      gap: 8px;
                      width: 376px;
                    "
                  >
                    <span
                      class="content-normal"
                      :class="{
                        'title-padding':
                          !field.allowComments ||
                          (deadlineExpired &&
                            !field.editComment &&
                            !field.editComment),
                        'title-top-padding':
                          field.allowComments &&
                          (field.editComment ||
                            field.comment ||
                            !deadlineExpired),
                      }"
                      >{{ field.title.et }}</span
                    >
                    <div
                      v-if="
                        field.allowComments &&
                        (!deadlineExpired || (deadlineExpired && field.comment))
                      "
                      style="
                        padding-bottom: 16px;
                        display: flex;
                        flex-wrap: nowrap;
                      "
                    >
                      <div
                        style="display: flex; flex-wrap: nowrap; gap: 8px"
                        @click="() => openEditComment(field)"
                        v-if="
                          !field.comment &&
                          !field.editComment &&
                          !submitted &&
                          !deadlineExpired
                        "
                      >
                        <img
                          height="16"
                          width="16"
                          :src="require('@/assets/images/chat-no-bubble.svg')"
                          alt="icon"
                        />
                        <div class="link-small" style="font-weight: 600">
                          +
                          {{
                            $t("addComment").charAt(0).toUpperCase() +
                            $t("addComment").slice(1)
                          }}
                        </div>
                      </div>
                      <div
                        v-if="
                          field.editComment ||
                          (field.comment && !field.editComment)
                        "
                        style="display: flex; gap: 8px; width: 100%"
                        class="comment-text-area"
                      >
                        <img
                          height="16"
                          width="16"
                          :src="require('@/assets/images/chat-no-bubble.svg')"
                          alt="icon"
                        />
                        <textarea
                          v-if="field.editComment"
                          id="textarea"
                          style="white-space: pre-wrap"
                          class="task-textarea content-normal"
                          rows="5"
                          ref="commentbox"
                          v-model="field.comment"
                          @focusout="() => closeEditComment(field)"
                        />
                        <div
                          v-if="field.comment && !field.editComment"
                          @click="() => openEditComment(field)"
                          class="content-small comment-block"
                        >
                          {{ field.comment }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="display: flex; gap: 32px; flex-wrap: nowrap">
                    <div style="width: 116px">
                      <span
                        style="padding-left: 16px"
                        v-if="submitted || deadlineExpired || field.name"
                      >
                        {{ field.quantity ? field.quantity : 0 }}
                      </span>
                      <div
                        v-else
                        style="
                          padding: 4px 12px 4px 4px;
                          display: flex;
                          gap: 4px;
                          align-items: center;
                          max-height: 52px;
                        "
                      >
                        <v-text-field
                          v-model="field.quantity"
                          outlined
                          type="number"
                          class="field-unit-price"
                          v-on:keyup="restrictValue(field)"
                          @input="
                            calculateMonthSummary(
                              field.id,
                              field.unitPrice,
                              field.quantity
                            )
                          "
                        />
                        <div
                          class="value-update-arrows"
                          style="
                            display: flex;
                            cursor: pointer;
                            flex-direction: column;
                          "
                        >
                          <img
                            @click="incrementField(field)"
                            height="20"
                            width="20"
                            :src="require('@/assets/images/chevron-up.svg')"
                            alt="inc"
                          />
                          <img
                            @click="decrementField(field)"
                            height="20"
                            width="20"
                            :src="require('@/assets/images/chevron-down.svg')"
                            alt="dec"
                          />
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; align-items: center">
                      <p class="content-semibold">
                        {{ calculateTotal(field) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="separator" />
      <div class="buttons-container">
        <div class="scorecard-month-summary">
          {{ $t("Total") }}: {{ monthSummary }}
        </div>

        <div class="flex justify-end" style="gap: 8px">
          <button
            v-if="!submitted && !deadlineExpired"
            @click="save(false)"
            class="transparent-button"
            style="background: black !important; color: white"
          >
            {{ $t("Save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    style="margin-top: 24px; position: absolute; top: 30%; left: 50%"
    class="loader"
  />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import axios from "axios";
import invoiceFilters from "@/helpers/data/store/filters/invoiceFilters";

export default {
  name: "ScorecardContent",
  components: {},
  props: ["year", "month", "obsYear", "obsMonth"],
  data() {
    return {
      loaded: false,
      obsField: null,
      template: null,
      knownValues: null,
      monthSummary: 0,
      submitted: false,
      monthStartDate: null,
      userScorecards: [],
    };
  },
  async created() {
    this.monthStartDate = moment(
      this.month + "-" + this.year,
      "M-YYYY"
    ).format();
    await this.initData();
  },
  watch: {
    async obsYear() {
      await this.initData();
    },
    async obsMonth() {
      await this.initData();
    },
    monthSummary() {
      this.userScorecards[this.year].months[this.month].summary =
        this.monthSummary;
    },
    submitted() {
      this.userScorecards[this.year].months[this.month].submitted =
        this.submitted;
    },
  },
  computed: {
    ...mapGetters(["user", "users", "scorecardTemplate"]),
    deadlineExpired() {
      return !(this.year === this.obsYear && this.month === this.obsMonth);
    },
    monthTwoFormat() {
      let nextMonth = this.month - 0 + 1;
      if (nextMonth === 13) nextMonth = 1;
      nextMonth = nextMonth.toString();
      if (nextMonth.length === 1) {
        return "0" + nextMonth;
      } else {
        return nextMonth;
      }
    },
  },
  methods: {
    ...mapActions(["setUserScorecards"]),
    incrementField(field) {
      console.log(field);
      field.quantity = parseFloat(field.quantity);
      field.quantity += 1.0;
      this.restrictValue(field);

      this.calculateMonthSummary(field.id, field.unitPrice, field.quantity);
    },
    decrementField(field) {
      if (field.quantity > 0) {
        field.quantity = parseFloat(field.quantity);
        field.quantity -= 1.0;
        this.restrictValue(field);
        this.calculateMonthSummary(field.id, field.unitPrice, field.quantity);
      }
    },
    restrictValue(field) {
      if (
        field.maxUnit &&
        field.quantity &&
        parseInt(field.quantity) > field.maxUnit
      ) {
        field.quantity = field.maxUnit.toString();
        field.unitPrice += 1.0;
        field.unitPrice -= 1.0;
        this.calculateMonthSummary(field.id, field.unitPrice, field.quantity);
      }
    },
    formatTotal(number) {
      const decimalsArray = number.toString().split(".");
      if (decimalsArray[1] && decimalsArray[1].length >= 2) {
        number = number.toFixed(2);
      }
      return number;
    },
    closeEditComment(field) {
      field.editComment = 0;
      if (field.comment === undefined) return;

      const obsField = this.userScorecards[this.year].months[
        this.month
      ].fields.find((f) => f.id === field.id);
      if (obsField) {
        obsField.comment = field.comment;
      } else {
        this.userScorecards[this.year].months[this.month].fields.push({
          id: field.id,
          quantity: field.quantity ? field.quantity : 0,
          comment: field.comment,
        });
      }
      this.save(false);
    },
    openEditComment(field) {
      if (this.submitted || this.deadlineExpired) return;

      field.editComment = 1;
      this.$nextTick(() => {
        this.$refs.commentbox[0].focus();
      });
    },
    getEmptyKnownValues() {
      return {
        openHousesCountHaveParticipants: 0,
        openHousesCountNoParticipants: 0,
        sales: 0,
        numberOfTransactions: 0,
        interestsCount: 0,
        addedClientsCountAtMonth: 0,
        contractOfExclusiveRepresentationCount: 0,
        contractOfRentalRepresentationCount: 0,
        contractOfDevelopmentProjectsCount: 0,
      };
    },
    calculateSalesPoints(paidBrokerageFee) {
      if (paidBrokerageFee < 500) {
        this.knownValues.sales = 0;
      } else if (paidBrokerageFee < 1000) {
        this.knownValues.sales = 1;
      } else if (paidBrokerageFee < 1500) {
        this.knownValues.sales = 2;
      } else if (paidBrokerageFee < 2000) {
        this.knownValues.sales = 3;
      } else if (paidBrokerageFee < 2500) {
        this.knownValues.sales = 4;
      } else if (paidBrokerageFee < 3500) {
        this.knownValues.sales = 20;
      } else if (paidBrokerageFee < 5000) {
        this.knownValues.sales = 30;
      } else if (paidBrokerageFee < 6500) {
        this.knownValues.sales = 40;
      } else if (paidBrokerageFee < 8000) {
        this.knownValues.sales = 50;
      } else if (paidBrokerageFee < 10000) {
        this.knownValues.sales = 55;
      } else if (paidBrokerageFee < 20000) {
        this.knownValues.sales = 60;
      } else if (paidBrokerageFee < 30000) {
        this.knownValues.sales = 65;
      } else {
        this.knownValues.sales = 70;
      }
    },
    getInvoicesFilterConditions() {
      const filterConditions = JSON.parse(
        JSON.stringify(invoiceFilters[0].conditions)
      );
      filterConditions.find((c) => c.name === "brokerId").value = {
        type: "user",
        value: this.user._id,
      };
      filterConditions.find((c) => c.name === "status").value = ["paid"];
      filterConditions.find((c) => c.name === "invoiceDate").value = "this_month";
      filterConditions.find((c) => c.name === "accountingType").value =
        "cash_accounting";
      return filterConditions;
    },
    async fetchOpenHousesHaveParticipants() {
      const response = await axios.get(
        `/api/task/creator/open-house/count/${this.monthStartDate}/${this.user._id}/haveParticipants`
      );
      if (response.status === 200) {
        let count = response.data;
        if (count > 10) count = 10;
        this.knownValues.openHousesCountHaveParticipants = count;
      }
    },
    async fetchOpenHousesNoParticipants() {
      const response = await axios.get(
        `/api/task/creator/open-house/count/${this.monthStartDate}/${this.user._id}/noParticipants`
      );
      if (response.status === 200) {
        let count = response.data;
        if (count > 10) count = 10;
        this.knownValues.openHousesCountNoParticipants = count;
      }
    },
    async fetchNumberOfInvoices() {
      const filterConditions = this.getInvoicesFilterConditions();
      filterConditions.find((c) => c.name === "invoiceTypes").value = [
        "rental_mediation",
        "sales_mediation",
        "collaboration",
      ];
      const response = await axios.post(
        `/api/invoices/brokers/${this.user._id}`,
        { filters: filterConditions }
      );
      if (
        response.status === 200 &&
        response.data &&
        response.data.length &&
        response.data.length > 0
      ) {
        this.knownValues.numberOfTransactions = this.getCorrectNumberOfInvoices(
          response.data
        );
      }
    },
    getCorrectNumberOfInvoices(arrayOfInvoices) {
      let result = 0;
      for (const invoice of arrayOfInvoices) {
        if (invoice.sharedBrokers?.length > 0) {
          if (invoice.broker.userId === this.user._id) {
            result += parseFloat(invoice.broker.share.percentage) / 100;
          } else {
            let sharedBroker = invoice.sharedBrokers.find(
              (sharedBroker) => sharedBroker._id === this.user._id
            );
            result += parseFloat(sharedBroker.share.percentage) / 100;
          }
        } else {
          result++;
        }
      }
      const sumDecimalsArray = result.toString().split(".");
      if (sumDecimalsArray[1] && sumDecimalsArray[1].length >= 2) {
        result = result.toFixed(2);
        result = parseFloat(result);
      }
      return result;
    },
    async fetchMonthSales() {
      const filterConditions = this.getInvoicesFilterConditions();
      const response = await axios.post(`/api/invoices/brokers`, {
        filters: filterConditions,
      });
      if (
        response.status === 200 &&
        response.data &&
        response.data.invoices &&
        response.data.invoices.length > 0
      ) {
        const paidInvoices = response.data.invoices[0].sum;
        this.calculateSalesPoints(paidInvoices);
      }
    },
    async fetchInterestsCount() {
      const response = await axios.get(
        `/api/projects/purchases-and-acquire-lease-count/${this.monthStartDate}/${this.user._id}`
      );
      if (response.status === 200) {
        this.knownValues.interestsCount = response.data;
      }
    },
    async fetchAddedClientsCountAtMonth() {
      const response = await axios.get(
        `/api/contact/addedclientscountatmonth/${this.monthStartDate}/${this.user._id}`
      );
      if (response.status === 200) {
        this.knownValues.addedClientsCountAtMonth = response.data;
      }
    },
    async fetchContractOfExclusiveRepresentationCount() {
      const response = await axios.get(
        `/api/project/contract-of-exclusive-representation-count/${this.monthStartDate}/${this.user._id}`
      );
      if (response.status === 200) {
        this.knownValues.contractOfExclusiveRepresentationCount = response.data;
      }
    },
    async fetchContractOfRentalRepresentationCount() {
      const response = await axios.get(
        `/api/project/contract-of-rental-representation-count/${this.monthStartDate}/${this.user._id}`
      );
      if (response.status === 200) {
        this.knownValues.contractOfRentalRepresentationCount = response.data;
      }
    },
    async fetchContractOfDevelopmentProjectsCount() {
      const response = await axios.get(
        `/api/project/contract-of-development-projects-count/${this.monthStartDate}/${this.user._id}`
      );
      if (response.status === 200) {
        this.knownValues.contractOfDevelopmentProjectsCount = response.data;
      }
    },
    async fetchKnownFields() {
      await Promise.all([
        this.fetchOpenHousesHaveParticipants(),
        this.fetchOpenHousesNoParticipants(),
        this.fetchMonthSales(),
        this.fetchNumberOfInvoices(),
        this.fetchInterestsCount(),
        this.fetchAddedClientsCountAtMonth(),
        this.fetchContractOfExclusiveRepresentationCount(),
        this.fetchContractOfRentalRepresentationCount(),
        this.fetchContractOfDevelopmentProjectsCount(),
      ]);
    },
    async initData() {
      const response = await axios.get(
        `/api/admin/scorecards/${this.user._id}`
      );
      if (response.status === 200) {
        this.userScorecards = response.data;
      }
      this.loaded = false;
      this.template = JSON.parse(JSON.stringify(this.scorecardTemplate));
      this.knownValues = JSON.parse(JSON.stringify(this.getEmptyKnownValues()));

      console.log("obsYear", this.obsYear);
      console.log("obsMonth", this.obsMonth);
      this.submitted =
        this.userScorecards[this.obsYear].months[this.obsMonth].submitted;
      if (!this.submitted && !this.deadlineExpired) {
        await this.fetchKnownFields();
      }
      this.mapUserScorecardToTemplate();
      this.loaded = true;
    },
    mapUserScorecardToTemplate() {
      const tempUserScorecard =
        this.userScorecards[this.obsYear].months[this.obsMonth];
      let tempMonthSummary = 0;
      this.template.topics.forEach((topics) =>
        topics.fields.forEach((field) => {
          let scorecardField = tempUserScorecard.fields.find(
            (s) => s.id === field.id
          );
          if (
            field.id &&
            !tempUserScorecard.submitted &&
            !this.deadlineExpired
          ) {
            if (scorecardField) {
              if (scorecardField) {
                scorecardField.quantity =
                  this.knownValues[field.name] !== undefined
                    ? this.knownValues[field.name]
                    : scorecardField.quantity;
              }
            } else {
              scorecardField = {
                id: field.id,
                quantity: this.knownValues[field.name] || 0,
              };
              tempUserScorecard.fields.push(scorecardField);
            }
            field.comment = scorecardField ? scorecardField.comment : "";
            field.quantity = scorecardField ? scorecardField.quantity : 0;
            field._links = scorecardField?._links
              ? scorecardField._links
              : null;
            this.$set(field, "editComment", 0);
            let finalQuantity =
              field.maxUnit && field.quantity > field.maxUnit
                ? field.maxUnit
                : field.quantity;
            tempMonthSummary += field.unitPrice * finalQuantity;
          }
        })
      );
      this.monthSummary = Math.round(tempMonthSummary * 100) / 100;
    },
    async save(_submit) {
      this.submitted = _submit;
      this.userScorecards[this.year].months[this.month].updatedAt =
        moment().format();
      this.calculateScorecardYearSummary();

      let response = await axios.post(
        `/api/admin/update-scorecards/${this.user._id}`,
        this.userScorecards
      );

      if (response.status === 200) {
        if (this.submitted) {
          this.toastSuccess(this.$t("ScorecardSubmitted"));
        } else {
          this.toastSuccess(this.$t("ScorecardSaved"));
        }
      }
    },
    async makeUserScorecard() {
      try {
        if (this.userScorecards[this.obsYear].months[this.obsMonth].fields)
          return;
      } catch (e) {
        let tempUserScorecard = this.userScorecards ? this.userScorecards : {};

        if (!tempUserScorecard[this.obsYear]) {
          tempUserScorecard[this.obsYear] = {
            months: {},
            summary: 0,
          };
        }

        tempUserScorecard[this.obsYear].months[this.obsMonth] = {
          fields: [],
          summary: 0,
          submitted: false,
          createdAt: moment().format(),
          updatedAt: moment().format(),
        };
        await this.setUserScorecards(tempUserScorecard);
      }
    },
    calculateScorecardYearSummary() {
      this.userScorecards[this.year].summary = 0;
      let obsMonths = this.userScorecards[this.year].months;

      Object.keys(obsMonths).forEach((month) => {
        this.userScorecards[this.year].summary += obsMonths[month].summary;
      });
    },
    calculateMonthSummary(fieldId, unitPrice, quantity) {
      quantity = quantity ? parseInt(quantity) : 0;
      unitPrice = unitPrice ? parseFloat(unitPrice) : 0;
      let field = this.userScorecards[this.year].months[this.month].fields.find(
        (f) => f.id === fieldId
      );
      let maxUnit = this.template.topics
        .flatMap((topic) => topic.fields)
        .find((tField) => tField.id === fieldId).maxUnit;
      let actualQuantity = maxUnit && quantity > maxUnit ? maxUnit : quantity;
      this.monthSummary = this.monthSummary || 0;

      if (field) {
        let previousQuantity =
          maxUnit && field.quantity > maxUnit ? maxUnit : field.quantity;
        this.monthSummary -= previousQuantity * unitPrice;
        field.quantity = quantity;
      } else {
        field = {
          id: fieldId,
          quantity: quantity,
        };
        this.userScorecards[this.year].months[this.month].fields.push(field);
      }
      this.monthSummary += actualQuantity * unitPrice;
      this.monthSummary = Math.round(this.monthSummary * 100) / 100;
    },
    calculateTotal(field) {
      let total = field.unitPrice * (field.quantity ? field.quantity : 0);

      let templateField = this.template.topics
        .find((topic) => topic.fields.some((f) => f.id == field.id))
        .fields.find((f) => f.id == field.id);

      if (templateField) {
        let maxTotal = templateField.maxUnit * field.unitPrice;
        if (total > maxTotal) {
          total = maxTotal;
        }
      }

      return this.formatTotal(total);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.comment-block {
  border: 1px solid #e6e8ec;
  border-radius: 4px;
  background: $light-grey;
  padding: 3px 7px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.field-unit-add-widget-comment {
  display: flex;
  gap: 8px;
  margin-top: 8px;

  p {
    font-size: 14px;
    line-height: 20px;
  }

  &:hover {
    cursor: pointer;
  }
}

.scorecard-month-summary {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 16px;
  gap: 8px;

  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.buttons-container {
  width: 100%;
  padding: 32px 8px 16px;
}

.separator {
  margin-left: -24px;
  width: 835px;
  background: $grey;
  height: 2px;
}

::v-deep .v-input__slot {
  min-height: 44px !important;
  font-size: 14px;
  margin-bottom: 0 !important;
}

::v-deep .v-text-field__details {
  display: none;
}

.field-unit-activity {
  width: 450px;
  margin-top: 12px;
}

.field-unit-price {
  width: 80px;
  max-width: 80px;
  max-height: 44px;
}

.remove-button {
  width: 24px;
  height: 24px;
  align-self: center;
  margin-bottom: 10px;
  margin-right: 12px;
  cursor: pointer;
}

.topic-title {
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
}

.scorecard-form-body {
}

.comment-text-area img {
  margin-top: 4px;
  min-width: 16px;
}

.topic-field-row {
  padding-left: 96px;
  display: flex;
  min-height: 52px;
}

.topic-field-row-content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 48px;
  width: 100%;
}

.topic-field-row-content-info {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  min-height: 24px;
}

.topic-row {
  padding: 12px;
  width: 100%;
  border-bottom: 1px solid $grey500;
}

.topic-row:last-of-type {
  border-bottom: 0;
}

.scorecard-form-header {
  padding: 4px 0 8px 0;
  border-bottom: 1px solid #000;
}

.title-padding {
  padding: 16px 0;
}

.title-top-padding {
  padding-top: 16px;
}

.scorecard-form {
  background-color: white;
  width: 835px;
  padding: 24px;
  border-radius: 8px;
}

.value-update-arrows img:hover {
  background-color: $grey;
  border-radius: 4px;
}
</style>
