var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loaded ? _c('div', {
    attrs: {
      "id": "scorecard-scorecard-content"
    }
  }, [_c('div', {
    staticStyle: {
      "margin-bottom": "16px"
    }
  }, [!_vm.deadlineExpired && !_vm.submitted ? _c('div', {
    staticStyle: {
      "font-size": "18px",
      "line-height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("SubmitDate")) + " 03." + _vm._s(_vm.monthTwoFormat) + ". 23:59 ")]) : _vm._e(), _c('div', [_vm._v(" " + _vm._s(_vm.$t("Status")) + ": "), _vm.userScorecards[_vm.year].months[_vm.month].submitted ? _c('span', {
    staticStyle: {
      "color": "#0aaf60"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Submitted")) + " ")]) : _c('span', {
    staticStyle: {
      "color": "#ff1e24"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("NotSubmitted")) + " ")])])]), _c('div', {
    staticClass: "scorecard-form"
  }, [_c('div', {
    staticClass: "scorecard-form-header content-small"
  }, [_c('span', {
    staticStyle: {
      "width": "88px",
      "margin-left": "108px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("UnitPrice")) + " ")]), _c('span', {
    staticStyle: {
      "width": "408px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Activity")) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t("Quantity")) + " ")])]), _vm.template && _vm.template.topics ? _c('div', {
    staticClass: "scorecard-form-body"
  }, _vm._l(_vm.template.topics, function (topic) {
    return _c('div', {
      key: topic.id,
      staticClass: "topic-row"
    }, [_c('div', {
      staticClass: "topic-title"
    }, [_vm._v(" " + _vm._s(topic.title.et) + " ")]), _vm._l(topic.fields, function (field) {
      return _c('div', {
        key: field.id
      }, [_c('div', {
        staticClass: "topic-field-row"
      }, [_c('div', {
        staticClass: "topic-field-row-content"
      }, [_c('span', {
        staticStyle: {
          "min-width": "40px",
          "height": "24px",
          "font-size": "18px",
          "line-height": "24px",
          "font-family": "Inter, sans-serif"
        }
      }, [_vm._v(" " + _vm._s(field.unitPrice) + " ")]), _c('div', {
        staticClass: "topic-field-row-content-info"
      }, [_c('div', {
        staticStyle: {
          "display": "flex",
          "flex-direction": "column",
          "gap": "8px",
          "width": "376px"
        }
      }, [_c('span', {
        staticClass: "content-normal",
        class: {
          'title-padding': !field.allowComments || _vm.deadlineExpired && !field.editComment && !field.editComment,
          'title-top-padding': field.allowComments && (field.editComment || field.comment || !_vm.deadlineExpired)
        }
      }, [_vm._v(_vm._s(field.title.et))]), field.allowComments && (!_vm.deadlineExpired || _vm.deadlineExpired && field.comment) ? _c('div', {
        staticStyle: {
          "padding-bottom": "16px",
          "display": "flex",
          "flex-wrap": "nowrap"
        }
      }, [!field.comment && !field.editComment && !_vm.submitted && !_vm.deadlineExpired ? _c('div', {
        staticStyle: {
          "display": "flex",
          "flex-wrap": "nowrap",
          "gap": "8px"
        },
        on: {
          "click": function () {
            return _vm.openEditComment(field);
          }
        }
      }, [_c('img', {
        attrs: {
          "height": "16",
          "width": "16",
          "src": require('@/assets/images/chat-no-bubble.svg'),
          "alt": "icon"
        }
      }), _c('div', {
        staticClass: "link-small",
        staticStyle: {
          "font-weight": "600"
        }
      }, [_vm._v(" + " + _vm._s(_vm.$t("addComment").charAt(0).toUpperCase() + _vm.$t("addComment").slice(1)) + " ")])]) : _vm._e(), field.editComment || field.comment && !field.editComment ? _c('div', {
        staticClass: "comment-text-area",
        staticStyle: {
          "display": "flex",
          "gap": "8px",
          "width": "100%"
        }
      }, [_c('img', {
        attrs: {
          "height": "16",
          "width": "16",
          "src": require('@/assets/images/chat-no-bubble.svg'),
          "alt": "icon"
        }
      }), field.editComment ? _c('textarea', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: field.comment,
          expression: "field.comment"
        }],
        ref: "commentbox",
        refInFor: true,
        staticClass: "task-textarea content-normal",
        staticStyle: {
          "white-space": "pre-wrap"
        },
        attrs: {
          "id": "textarea",
          "rows": "5"
        },
        domProps: {
          "value": field.comment
        },
        on: {
          "focusout": function () {
            return _vm.closeEditComment(field);
          },
          "input": function ($event) {
            if ($event.target.composing) return;
            _vm.$set(field, "comment", $event.target.value);
          }
        }
      }) : _vm._e(), _vm._v(" "), field.comment && !field.editComment ? _c('div', {
        staticClass: "content-small comment-block",
        on: {
          "click": function () {
            return _vm.openEditComment(field);
          }
        }
      }, [_vm._v(" " + _vm._s(field.comment) + " ")]) : _vm._e()]) : _vm._e()]) : _vm._e()]), _c('div', {
        staticStyle: {
          "display": "flex",
          "gap": "32px",
          "flex-wrap": "nowrap"
        }
      }, [_c('div', {
        staticStyle: {
          "width": "116px"
        }
      }, [_vm.submitted || _vm.deadlineExpired || field.name ? _c('span', {
        staticStyle: {
          "padding-left": "16px"
        }
      }, [_vm._v(" " + _vm._s(field.quantity ? field.quantity : 0) + " ")]) : _c('div', {
        staticStyle: {
          "padding": "4px 12px 4px 4px",
          "display": "flex",
          "gap": "4px",
          "align-items": "center",
          "max-height": "52px"
        }
      }, [_c('v-text-field', {
        staticClass: "field-unit-price",
        attrs: {
          "outlined": "",
          "type": "number"
        },
        on: {
          "keyup": function ($event) {
            return _vm.restrictValue(field);
          },
          "input": function ($event) {
            return _vm.calculateMonthSummary(field.id, field.unitPrice, field.quantity);
          }
        },
        model: {
          value: field.quantity,
          callback: function ($$v) {
            _vm.$set(field, "quantity", $$v);
          },
          expression: "field.quantity"
        }
      }), _c('div', {
        staticClass: "value-update-arrows",
        staticStyle: {
          "display": "flex",
          "cursor": "pointer",
          "flex-direction": "column"
        }
      }, [_c('img', {
        attrs: {
          "height": "20",
          "width": "20",
          "src": require('@/assets/images/chevron-up.svg'),
          "alt": "inc"
        },
        on: {
          "click": function ($event) {
            return _vm.incrementField(field);
          }
        }
      }), _c('img', {
        attrs: {
          "height": "20",
          "width": "20",
          "src": require('@/assets/images/chevron-down.svg'),
          "alt": "dec"
        },
        on: {
          "click": function ($event) {
            return _vm.decrementField(field);
          }
        }
      })])], 1)]), _c('div', {
        staticStyle: {
          "display": "flex",
          "align-items": "center"
        }
      }, [_c('p', {
        staticClass: "content-semibold"
      }, [_vm._v(" " + _vm._s(_vm.calculateTotal(field)) + " ")])])])])])])]);
    })], 2);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "separator"
  }), _c('div', {
    staticClass: "buttons-container"
  }, [_c('div', {
    staticClass: "scorecard-month-summary"
  }, [_vm._v(" " + _vm._s(_vm.$t("Total")) + ": " + _vm._s(_vm.monthSummary) + " ")]), _c('div', {
    staticClass: "flex justify-end",
    staticStyle: {
      "gap": "8px"
    }
  }, [!_vm.submitted && !_vm.deadlineExpired ? _c('button', {
    staticClass: "transparent-button",
    staticStyle: {
      "background": "black !important",
      "color": "white"
    },
    on: {
      "click": function ($event) {
        return _vm.save(false);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]) : _vm._e()])])])]) : _c('div', {
    staticClass: "loader",
    staticStyle: {
      "margin-top": "24px",
      "position": "absolute",
      "top": "30%",
      "left": "50%"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }