<template>
  <div id="admin-content" class="main-wrapper">
    <div v-if="!loaded">
      <div
        style="margin-top: 24px; position: absolute; top: 30%; left: 50%"
        v-if="!loaded"
        class="loader"
      />
    </div>
    <div
        v-else-if="filteredUsers && filteredUsers.length < 1"
        style="padding-top: 24px"
    >
      <h3>{{ $t("NoMatchesFound") }}</h3>
    </div>

    <Table
      v-if="filteredUsers && filteredUsers.length >= 1 && loaded"
      :fields="tableFields"
      :dataset="filteredUsers"
      :add-gap="true"
      :update-key="updateTable"
      :sort-again="sortUpdater"
      :scorecard-view="true"
      @scorecardClicked="scorecardClicked"
      :roundingDigits="0"
    />
    <ScorecardSidepanel
      v-if="scorecardSidepanel"
      :userScorecards="userScorecards"
      :obsUser="obsUser"
      :year="year"
      :month="obsMonth"
      @closePanel="closeScorecardPanel"
    />
    <!-- <UserSidepanel
      v-if="userPanelOpen"
      @overlayClicked="userPanelOpen = false"
      :userId="obsUser._id"
    /> -->

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Table from "@/components/common/Table";
import axios from "axios";
import ScorecardSidepanel from "@/components/Scorecard/ScorecardSidepanel";
import moment from "moment/moment";
//import UserSidepanel from "@/components/Admin/UserSidepanel";

export default {
  name: "UsersContent",
  components: {
    //UserSidepanel,
    ScorecardSidepanel,
    Table,
  },
  props: ["searchValue", "year"],
  data() {
    return {
      loaded: false,
      //userPanelOpen: false,
      usersScorecards: null,
      sortUpdater: 0,
      updateTable: 0,
      originalArr: [],
      filteredUsers: [],
      scorecardSidepanel: false,
      userScorecards: null,
      obsUser: null,
      obsMonth: null,
      tableFields: [
        {
          fieldValue: this.$t("FirstName"),
          jsonValue: "firstname",
          style: "min-width:120px; max-width:120px; height:52px",
        },
        {
          fieldValue: this.$t("LastName"),
          jsonValue: "lastname",
          style: "min-width:120px; max-width:120px; height:52px",
        },
        ...moment.monthsShort().map((m, i) => {
          return {
            fieldValue: m,
            jsonValue: i + 1 + "",
            style: "min-width:72px; max-width:72px; height:52px",
          }
        }),
        {
          fieldValue: this.$t("Summary"),
          jsonValue: "summary",
          style: "min-width:120px; max-width:120px; height:52px",
        },
      ],
    };
  },
  created() {
    this.setup();
  },
  mounted() {},
  watch: {
    searchValue() {
      let search = this.searchValue;
      search = search.toLowerCase();
      if (search) {
        this.filteredUsers = this.originalArr.filter(
          (item) =>
            item.firstname.toLowerCase().indexOf(search) > -1 ||
            item.lastname.toLowerCase().indexOf(search) > -1
        );
      } else {
        this.filteredUsers = this.originalArr;
      }
      this.sortUpdater++;
      this.updateTable++;
    },
    year() {
      this.originalArr = [];
      this.filteredUsers = [];
      this.mapUsersScorecardsToTable();
      this.updateTable++;
    },
    async groupId() {
      if (this.groupId) {
        await this.setup();
        this.updateTable++;
      }
    },
    activeScorecardGroupFilter: async function() {
      await this.initUsersScoreCards();
      this.mapUsersScorecardsToTable();
      this.sortUpdater++;
      this.updateTable++;
    }
  },
  computed: {
    ...mapGetters(["user", "users", "groups", "activeScorecardGroupFilter"]),
    selectedFilterUsers() {
      if (!this.activeScorecardGroupFilter) {
        return this.users.filter(user => user.userStatus === 'Active');
      }

      const groupUsers = this.activeScorecardGroupFilter.type === 'group'
          ? this.groups[this.activeScorecardGroupFilter.value].users
          : null;

      return this.users.reduce((accumulator, currentUser) => {
        if (this.activeScorecardGroupFilter.type === 'user' && currentUser._id === this.activeScorecardGroupFilter.value) {
          return [currentUser];
        }

        if (currentUser.userStatus !== 'Active') {
          return accumulator;
        }

        if (this.activeScorecardGroupFilter.type === 'group' && groupUsers[currentUser._id]) {
          accumulator.push(currentUser);
        }

        return accumulator;
      }, []);
    },

  },
  methods: {
    ...mapActions(['setUsersScoreCards', 'clearUsersScoreCards']),
    async setup() {
      this.loaded = false;
      await this.initUsersScoreCards();
      this.mapUsersScorecardsToTable();
      this.mapFieldsToDataset();
      this.loaded = true;
    },
    closeScorecardPanel(userScoreCard) {
      this.scorecardSidepanel = false;
      this.usersScorecards
          .find(item => item._id === this.obsUser._id).scorecards[this.year].months[this.obsMonth] = userScoreCard;
      this.mapUsersScorecardsToTable();
      this.updateTable++;
      this.sortUpdater++;
    },
    scorecardClicked(userId, field) {
      this.obsUser = this.users.find(user => user._id === userId)
      /* if ((field === "firstname" || field === "lastname") && (this.user.isAdmin || this.user.isGroupAdmin)) {
        this.userPanelOpen = true;
      } else { */
        this.openScorecard(field)
      //}
    },
    openScorecard(month) {
      if (
          (this.user.access.accessLevel === 'user' && this.obsUser._id !== this.user._id) ||
          (this.user.access.accessLevel === 'group_admin' && 
          !this.obsUser.groups.some(obsGroup => Object.prototype.hasOwnProperty.call(this.user.access.groups, obsGroup._id)) && this.obsUser._id !== this.user._id)
      ) {
          return;
      }

      this.userScorecards = this.usersScorecards
          .find(user => user._id === this.obsUser._id)
          .scorecards

      try {
        if (!this.userScorecards[this.year].months[month]) throw "error"
      } catch (e) {
        return;
      }

      this.obsMonth = month;
      this.scorecardSidepanel = true;
    },
    mapUsersScorecardsToTable() {
      if (!this.usersScorecards) return
      this.originalArr = [];
      for (let i = 0; i < this.selectedFilterUsers.length; i++) {
        const user = this.selectedFilterUsers[i];
        let tableUser = {
          id: user._id,
          firstname: user.firstname,
          lastname: user.lastname,
        };

        const usersScorecard = this.usersScorecards
            .find(item => item._id === user._id)

        if (usersScorecard &&
            usersScorecard.scorecards &&
            usersScorecard.scorecards[this.year]) {
          tableUser["summary"] = usersScorecard.scorecards[this.year].summary;
        }

        if (usersScorecard &&
            usersScorecard.scorecards &&
            usersScorecard.scorecards[this.year] &&
            usersScorecard.scorecards[this.year].months) {
          const obsMonths = usersScorecard.scorecards[this.year].months;
          Object.keys(obsMonths).forEach(monthName => {
            tableUser[monthName] = obsMonths[monthName].summary;
          });
        }

        this.originalArr.push(tableUser);
      }
      this.filteredUsers = [...this.originalArr];
      
    },
    getTableFieldsKeys() {
      return this.tableFields.map(field => field.jsonValue);
    },
    getTableFields() {
      return this.tableFields.map(field => field.fieldValue);
    },
    mapFieldsToDataset() {
      const tableFieldsKeys= this.getTableFieldsKeys();
      const mappedUsers = this.filteredUsers.map(user => {
        const mappedUser = {...user}; 
        tableFieldsKeys.forEach(jsonKey => {
          if (!Object.prototype.hasOwnProperty.call(mappedUser, jsonKey)) {
            mappedUser[jsonKey] = null;
          }
        });
        return mappedUser;
      });
      const tableFields = this.getTableFields();

      const obj = {
          fieldsKeys: tableFieldsKeys,
          fields: tableFields,
          dataset: mappedUsers,
      };

      this.setUsersScoreCards(obj);
    },
    async initUsersScoreCards() {
      const usersIds = this.selectedFilterUsers.map(u => u._id);
      const response = await axios.post(`/api/admin/all/scorecards`, usersIds);
      if (response.status === 200) {
        this.usersScorecards = response.data;
      }
    },
  },
  beforeDestroy() {
    this.clearUsersScoreCards();
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  background-color: #f4f5f7;
  flex-wrap: nowrap;
  flex-direction: column;
  padding-bottom: 10vh;
}
</style>
