<template>
  <div
      class="overlay-detail-panel"
      id="overlay-customer"
  >
    <div
        class="scorecard-wrapper"
        v-click-outside="() => $emit('closePanel', scorecard)"
    >

      <div class="scorecard-content">
        <div class="user-info-wrapper">
          <v-row class="flex justify-space-between">
            <h4>
              {{ obsUser.name }}
            </h4>
            <div
                v-if="haveAccessToEdit"
                class="button"
                style="margin: 0 2px"
                @click="editScorecard"
            >
              <img
                  src="../../assets/images/edit.svg"
                  alt=""
                  style="height: 16px; width: 16px"
              />
              <p class="button-text">
                {{ $t('Edit') }}
              </p>
            </div>
          </v-row>
          <p>
            {{ year }} {{ mappedMonth }}
          </p>
        </div>
        <div
            v-if="template && template.topics"
            class="scorecard-form-body"
            :key="scorecardUpdater"
        >
          <div class="scorecard-form-header content-small">
          <span style="width: 64px; margin-left: 108px;">
            {{ $t('UnitPrice') }}
          </span>
            <span style="width: 260px;">
            {{ $t('Activity') }}
          </span>
            <span style="width: 48px">
            {{ $t('Quantity') }}
          </span>
            <span style="width: 48px">
            {{ $t('Points') }}
          </span>
          </div>

          <div
              v-for="(topic) in template.topics"
              :key="topic.id"
              class="topic-row"
          >
          <span class="topic-title">
            {{ topic.title.et }}
          </span>
            <div
                v-for="field in topic.fields"
                :key="field.id"
                class="topic-field-row"
            >
            <span class="field-unit-price">
              {{ field.unitPrice }}
            </span>
              <span class="field-unit-activity">
              <span>
              {{ field.title.et }}
              </span>
              <div class="field-unit-add-widget-comment"
                   v-if="field.allowComments"
              >
                <img
                    src="@/assets/images/chat-no-bubble.svg"
                    style="width: 20px; height: 20px"
                    alt=""
                />
                <div @click="() => openEditComment(field)"
                     v-if="!field.comment && !field.editComment && haveAccessToEdit"
                >
                  <p class="link">
                    +
                    {{
                      $t("addComment").charAt(0).toUpperCase() +
                      $t("addComment").slice(1)
                    }}
                  </p>
                </div>

                <textarea
                    v-if="field.editComment"
                    id="textarea"
                    style="white-space: pre-wrap"
                    class="task-textarea content-normal"
                    rows="5"
                    ref="commentbox"
                    v-model="field.comment"
                    @focusout="() => closeEditComment(field)"
                />
                <div
                    v-if="field.comment && !field.editComment"
                    @click="() => openEditComment(field)"
                    class="content-normal comment-block"
                >
                  {{ field.comment }}
                </div>
              </div>
            </span>
              <span v-if="edit">
              <v-text-field
                  v-model="field.quantity"
                  outlined
                  type="number"
                  class="field-unit-price"
                  @input="
                  calculateMonthSummary(
                    field.id,
                    field.unitPrice,
                    field.quantity
                  )
                "
              />
            </span>
              <span v-else class="field-number">
                {{ field.quantity }}
            </span>
              <span class="field-number">
                {{ calculatePoints(field) }}
            </span>
            </div>
          </div>
        </div>
      </div>
      <div class="scorecard-footer"
      >
        <p>
          {{ $t("Total") }}: {{ monthSummary }}
        </p>
        <button
            v-if="edit"
            @click="save"
            class="transparent-button"
            style="background: black !important; color: white"
        >
          <img
              src="../../assets/images/checkmarkCircled.svg"
              style="height: 16px; width: 16px"
              alt=""
          />
          <span>
            {{ $t("Save") }}
          </span>
        </button>
      </div>

    </div>

  </div>
</template>
<script>

import {mapActions, mapGetters} from "vuex";
import moment from "moment/moment";
import axios from "axios";

export default {
  name: "scorecardSidepanel",
  props: ["userScorecards", "obsUser", "year", "month"],
  components: {
  },
  data: () => ({
    template: null,
    widgetPosition: null,
    viewLink: false,
    obsLinks: [],
    edit: false,
    monthSummary: 0,
    scorecards: null,
    scorecard: null,
    scorecardUpdater: 0
  }),
  created() {
    this.scorecards = this.userScorecards;
    this.scorecard = this.scorecards[this.year].months[this.month];
    this.monthSummary = this.scorecard.summary;
    this.template = JSON.parse(JSON.stringify(this.scorecardTemplate));
    this.initData();
  },
  computed: {
    ...mapGetters(["scorecardTemplate", "user", "userScorecard"]),
    mappedMonth() {
      return moment(this.month, 'M').format('MMMM')
    },
    haveAccessToEdit() {
      return (this.user.isAdmin ||
          (this.user.isGroupAdmin &&
          this.obsUser.groups.some(g => this.user.groups.some(g2 => g.name === g2.name)))
      )
    }
  },
  methods: {
    ...mapActions(["setUserScorecards"]),
    closeEditComment(field) {
      field.editComment = 0;
      this.scorecardUpdater++
      if (field.comment === undefined) return;

      const obsField = this.scorecard.fields.find(f => f.id === field.id);
      if (obsField) {
        obsField.comment = field.comment;
      } else {
        this.scorecard.fields.push({
          id: field.id,
          quantity: field.quantity ? field.quantity : 0,
          comment: field.comment
        })
      }
      this.save();
    },
    openEditComment(field) {
      if (!this.user.isAdmin && !this.user.isGroupAdmin) return;

      field.editComment = 1;
      this.scorecardUpdater++
      this.$nextTick(() => {
        this.$refs.commentbox[0].focus();
      });
    },
    async save() {
      this.scorecard.updatedAt = moment().format()
      this.scorecard.summary = this.monthSummary
      this.scorecards[this.year].months[this.month] = this.scorecard
      this.calculateScorecardYearSummary();

      let response = await axios.post(
          `/api/admin/update-scorecards/${this.obsUser._id}`,
          this.scorecards
      );

      if (response.status === 200) {
        this.toastSuccess(this.$t("ScorecardSaved"));
        this.edit = false;
        if (this.obsUser._id === this.user._id) {
          await this.setUserScorecards(this.scorecards)
        }
      } else {
        this.toastError(this.$t("Error"))
      }
    },
    calculateMonthSummary(fieldId, unitPrice, inputQuantity) {
      inputQuantity = inputQuantity ? parseInt(inputQuantity) : 0;
      let field = this.scorecard.fields.find(f => f.id === fieldId);
      let maxUnit = this.template.topics.flatMap(topic => topic.fields)
        .find(tField => tField.id === fieldId).maxUnit;
      let actualQuantity = maxUnit && inputQuantity > maxUnit ? maxUnit : inputQuantity;
      if (field) {
        let previousQuantity = maxUnit && field.quantity > maxUnit ? maxUnit : field.quantity;
        this.monthSummary -= previousQuantity * unitPrice;
        field.quantity = inputQuantity;
      } else {
        field = {
          id: fieldId,
          quantity: inputQuantity
        };
        this.scorecard.fields.push(field);
      }
      this.monthSummary += actualQuantity * unitPrice;
      this.monthSummary = Math.round(this.monthSummary * 100) / 100;
    },
    calculateScorecardYearSummary() {
      this.scorecards[this.year].summary = 0;
      let obsMonths = this.scorecards[this.year].months;

      Object.keys(obsMonths).forEach((month) => {
        this.scorecards[this.year].summary += obsMonths[month].summary;
      });
    },
    editScorecard() {
      this.edit = !this.edit
    },
    initData() {
      let gate = false;
      this.scorecard.fields.forEach(userField => {
        for (let topic of this.template.topics) {
          gate = false;
          for (let field of topic.fields) {
            this.$set(field, "editComment", 0)
            if (field.id !== userField.id) continue;
            field.comment = userField.comment;
            field.quantity = userField.quantity.toString();
            gate = true;
            break;
          }
          if (gate) break;
        }
      })
    },
    quantityToDisplay(field) {
      if (field.quantity && field.quantity > field.maxUnit) {
        return field.maxUnit;
      } else {
        return field.quantity ? field.quantity : 0;
      }
    },
    calculatePoints(field) {
      let quantity = this.quantityToDisplay(field);
      return quantity ? Math.round(field.unitPrice * quantity * 100) / 100  : 0;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

.scorecard-content {
  height: calc(100% - 104px);
  overflow-y: scroll;
}

.comment-block {
  border: 1px solid #E6E8EC;
  border-radius: 4px;
  background: $light-grey;
  padding: 4px 8px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.field-unit-add-widget-comment {
  display: flex;
  gap: 8px;
  margin-top: 8px;

  p {
    font-size: 14px;
    line-height: 20px;
  }

  &:hover {
    cursor: pointer;
  }
}

.button {
  border: 1px solid $grey500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 24px;
  padding: 0 4px;
  cursor: pointer;
  gap: 4px;
  font-size: 12px;

  &:hover {
    background-color: #F4F5F7;
  }
}

.field-number {
  margin-top: 12px;
  width: 48px;
  text-align: center
}

.field-link {
  text-decoration: underline;
  white-space: nowrap;
  align-self: start;
  line-height: 20px;
  cursor: pointer;
  gap: 8px;
}

.scorecard-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 640px;
  background: white;
  height: 104px;
  border-top: 1px solid $grey500;
  padding: 32px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 12px;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.user-info-wrapper {
  display: grid;
  padding: 40px 32px 32px 32px;
  border-bottom: 1px solid $grey500;
  gap: 8px;
}

.scorecard-form-body {
  padding: 32px 16px 32px 16px;
}

.topic-field-row {
  padding-left: 108px;
  margin: 8px 0;
  display: flex;
  gap: 24px;
}

.topic-title {
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  padding-left: 12px;
}

.field-unit-activity {
  width: 260px;
  margin-top: 12px;
}

.field-unit-price {
  width: 64px;
  max-width: 64px;
  //display: flex;
  margin-top: 12px;
}

.scorecard-form-header {
  padding: 4px 0 8px 0;
  border-bottom: 2px solid #000;
  gap: 24px;
}

.topic-row {
  padding: 12px 0;
  width: 100%;
  border-bottom: 1px solid $grey500;
}

.topic-row:last-of-type {
  border-bottom: 0;
}

.scorecard-wrapper {
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 7;
  position: fixed;
  width: 640px;

  border: 1px solid #e6e8ec;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.overlay-detail-panel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 101;
}

</style>
